/** @format */
import analytics from './routes/analytics';
import settings from './routes/settings';
import objects from './routes/objects';


const cabinet = [
  {
    path: '/workStatistics',
    name: 'workStatistics',
    meta: { auth: 1, title: '' },
    component: () => {
      return import('@/views/cabinet/workStatistics.vue');
    },
  },

  {
    path: '/myData',
    name: 'myData',
    meta: { auth: 1, title: '' },
    component: () => {
      return import('@/views/cabinet/crypt.vue');
    },
  },
  {
    path: '/myPartner',
    name: 'myPartner',
    meta: { auth: 1, title: '' },
    component: () => {
      return import('@/views/cabinet/partner.vue');
    },
  },
  {
    path: '/myClient',
    name: 'myClient',
    meta: { auth: 1, title: '' },
    component: () => {
      return import('@/views/cabinet/myClient.vue');
    },
  },
];

const routes = [
  {
    path: '/index',
    name: 'index',
    meta: { auth: 1, title: 'Главная' },
    component: () => {
      return import('@/views/index.vue');
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: { auth: 1, title: 'Задачи' },
    component: () => {
      return import('@/views/tasks.vue');
    },
  },
  {
    path: '/tasks/:id',
    name: 'tasks_view',
    meta: { auth: 1, title: 'Задача' },
    component: () => {
      return import('@/views/views/tasksView.vue');
    },
  },
  {
    path: '/tasks-goods',
    name: 'tasks-goods',
    meta: { auth: 1, title: 'Покупки (чек-лист)' },
    component: () => {
      // return import('@/views/tasks-goods.vue');
      return import('@/views/tasks-buying.vue');
    },
  },

  {
    path: '/tasks-goods/:id',
    name: 'tasks-goods_view',
    meta: { auth: 1, title: 'Задача (чек-лист)' },
    component: () => {
      // return import('@/views/views/tasksView.vue');
      return import('@/views/views/tasksBuyingView.vue');
    },
  },
  {
    path: '/buying',
    name: 'buying',
    meta: { auth: 1, title: 'Покупки по чек-листу old' },
    component: () => {
      return import('@/views/tasks-goods.vue');
    },
  },
  {
    path: '/buying/:id',
    name: 'buying_view',
    meta: { auth: 1, title: 'Покупка old' },
    component: () => {
      return import('@/views/views/tasksView.vue');
    },
  },
  {
    path: '/payOrder',
    name: 'payOrder',
    meta: { auth: 1, title: 'Заявка на оплату' },
    component: () => {
      return import('@/views/payOrder.vue');
    },
  },
  {
    path: '/payOrder/:id',
    name: 'payOrder_view',
    meta: { auth: 1, title: 'Заявка на оплату' },
    component: () => {
      return import('@/views/views/payOrderView.vue');
    },
  },
  {
    path: '/rent',
    name: 'rent',
    meta: { auth: 1, title: 'Аренда' },
    component: () => {
      return import('@/views/rent.vue');
    },
  },
  {
    path: '/rent/:id',
    name: 'rent_view',
    meta: { auth: 1, title: 'Аренда' },
    props: (route) => ({ tab: route.query.tab, ...route.params }),
    component: () => {
      return import('@/views/views/rentView.vue');
    },
  },

  {
    path: '/leads',
    name: 'leads',
    meta: { auth: 1, title: 'Лиды' },
    component: () => {
      return import('@/views/leads.vue');
    },
  },
  {
    path: '/leads/:id',
    name: 'leads_view',
    meta: { auth: 1, title: 'Лид' },
    props: (route) => ({ tab: route.query.tab, ...route.params }),
    component: () => {
      return import('@/views/views/leadView.vue');
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    meta: { auth: 1, title: 'Договора' },
    component: () => {
      return import('@/views/contracts.vue');
    },
  },
  {
    path: '/contracts/:id',
    name: 'contracts_view',
    meta: { auth: 1, title: 'Договор' },
    component: () => {
      return import('@/views/views/contractView.vue');
    },
  },
  {
    path: '/considers',
    name: 'considers',
    meta: { auth: 1, title: 'Предложения' },
    component: () => {
      return import('@/views/considers.vue');
    },
  },
  {
    path: '/considers/:id',
    name: 'considers_view',
    meta: { auth: 1, title: 'Предложение' },
    component: () => {
      return import('@/views/views/considersView.vue');
    },
  },

  {
    path: '/investors',
    name: 'investors',
    meta: { auth: 1, title: 'Инвесторы' },
    component: () => {
      return import('@/views/investors.vue');
    },
  },
  {
    path: '/investors/:id',
    name: 'investors_view',
    meta: { auth: 1, title: 'Инвесторы' },
    component: () => {
      return import('@/views/views/investorsView.vue');
    },
  },

  {
    path: '/clients',
    name: 'clients',
    meta: { auth: 1, title: 'Клиенты' },
    component: () => {
      return import('@/views/clients.vue');
    },
  },
  {
    path: '/clients/:id',
    name: 'clients_view',
    meta: { auth: 1, title: 'Клиенты' },
    component: () => {
      return import('@/views/views/clientsView.vue');
    },
  },

  {
    path: '/finance',
    name: 'finance',
    meta: { auth: 1, title: 'Финансы' },
    component: () => {
      return import('@/views/finance.vue');
    },
  },
  {
    path: '/import',
    name: 'import',
    meta: { auth: 1, title: 'Import' },
    component: () => {
      return import('@/views/importFinance.vue');
    },
  },
  {
    path: '/storageGoods',
    name: 'storageGoods',
    meta: { auth: 1, title: 'Товары' },
    component: () => {
      return import('@/views/storageGoods.vue');
    },
  },
  {
    path: '/goodsIncome',
    name: 'goodsIncome',
    meta: { auth: 1, title: 'Приемка' },
    component: () => {
      return import('@/views/documents/goodsIncome.vue');
    },
  },
  {
    path: '/goodsIncome/:id',
    name: 'goodsIncome_view',
    meta: { auth: 1, title: 'Приемка товаров' },
    component: () => {
      return import('@/views/documents/views/goodsIncomeView.vue');
    },
  },
  {
    path: '/storageAccum',
    name: 'storageAccum',
    meta: { auth: 1, title: 'Остатки' },
    component: () => {
      return import('@/views/storageAccum.vue');
    },
  },
  {
    path: '/goodsReserve',
    name: 'goodsReserve',
    meta: { auth: 1, title: 'Резервирование товаров' },
    component: () => {
      return import('@/views/documents/goodsReserve.vue');
    },
  },
  {
    path: '/goodsReserve/:id',
    name: 'goodsReserve_view',
    meta: { auth: 1, title: 'Резервирование' },
    component: () => {
      return import('@/views/documents/views/goodsReserveView.vue');
    },
  },
  {
    path: '/goodsMoving',
    name: 'goodsMoving',
    meta: { auth: 1, title: 'Движение товаров' },
    component: () => {
      return import('@/views/documents/goodsMoving.vue');
    },
  },
  {
    path: '/goodsMoving/:id',
    name: 'goodsMoving_view',
    meta: { auth: 1, title: 'Движение товаров' },
    component: () => {
      return import('@/views/documents/views/goodsMovingView.vue');
    },
  },
  {
    path: '/ordersGoods',
    name: 'ordersGoods',
    meta: { auth: 1, title: 'Заказ товаров' },
    component: () => {
      return import('@/views/documents/ordersGoods.vue');
    },
  },

  {
    path: '/ordersGoods/:id',
    name: 'ordersGoods_view',
    meta: { auth: 1, title: 'Заказ товаров' },
    component: () => {
      return import('@/views/documents/views/orderGoodsView.vue');
    },
  },
  {
    path: '/ordersService',
    name: 'ordersService',
    meta: { auth: 1, title: 'Заказ услуг' },
    component: () => {
      return import('@/views/documents/ordersService.vue');
    },
  },
  {
    path: '/ordersService/:id',
    name: 'ordersService_view',
    meta: { auth: 1, title: 'Заказ услуг' },
    component: () => {
      return import('@/views/documents/views/orderServiceView.vue');
    },
  },
  {
    path: '/ordersCashBook',
    name: 'ordersCashBook',
    meta: { auth: 1, title: 'Кассовая книга' },
    component: () => {
      return import('@/views/documents/ordersCashBook.vue');
    },
  },
  {
    path: '/ordersCashOut',
    name: 'ordersCashOut',
    meta: { auth: 1, title: 'Расходный ордер' },
    component: () => {
      return import('@/views/documents/ordersCashOut.vue');
    },
  },
  {
    path: '/ordersCashIn',
    name: 'ordersCashIn',
    meta: { auth: 1, title: 'Приходный ордер' },
    component: () => {
      return import('@/views/documents/ordersCashIn.vue');
    },
  },
  {
    path: '/cashRemainder',
    name: 'cashRemainder',
    meta: { auth: 1, title: 'Остатки по кассам' },
    component: () => {
      return import('@/views/documents/cashRemainder.vue');
    },
  },
  {
    path: '/object-buy',
    name: 'objectBuy',
    meta: { auth: 1, title: 'Договора покупок' },
    component: () => {
      return import('@/views/documents/objectBuy.vue');
    },
  },

  {
    path: '/object-buy/:id',
    name: 'objectBuy_view',
    meta: { auth: 1, title: 'Договор покупки' },
    component: () => {
      return import('@/views/documents/views/objectBuyView.vue');
    },
  },
  {
    path: '/object-sell',
    name: 'objectSell',
    meta: { auth: 1, title: 'Договора продажи' },
    component: () => {
      return import('@/views/documents/objectSell.vue');
    },
  },

  {
    path: '/object-sell/:id',
    name: 'objectSell_view',
    meta: { auth: 1, title: 'Договор продажи' },
    component: () => {
      return import('@/views/documents/views/objectSellView.vue');
    },
  },
  {
    path: '/act-work',
    name: 'actWork',
    meta: { auth: 1, title: 'Подрядчик - Акты выполненых работ' },
    component: () => {
      return import('@/views/documents/actWork.vue');
    },
  },
  {
    path: '/act-work/:id',
    name: 'actWork_view',
    meta: { auth: 1, title: 'Подрядчик - Акт выполненых работ' },
    component: () => {
      return import('@/views/documents/views/actWorkView.vue');
    },
  },
  {
    path: '/act-work-client',
    name: 'actWorkClient',
    meta: { auth: 1, title: 'Клиент - Акты выполненых работ' },
    component: () => {
      return import('@/views/documents/actWorkClient.vue');
    },
  },
  {
    path: '/act-work-client/:id',
    name: 'actWorkClient_view',
    meta: { auth: 1, title: 'Клиент - Акт выполненых работ' },
    component: () => {
      return import('@/views/documents/views/actWorkClientView.vue');
    },
  },
  {
    path: '/loan-get',
    name: 'loanGet',
    meta: { auth: 1, title: 'Журнал взятых кредитов' },
    component: () => {
      return import('@/views/documents/loanGet.vue');
    },
  },

  {
    path: '/loan-get/:id',
    name: 'loanGet_view',
    meta: { auth: 1, title: 'Взятие кредита' },
    component: () => {
      return import('@/views/documents/views/loanGetView.vue');
    },
  },
  {
    path: '/estimate',
    name: 'estimate',
    meta: { auth: 1, title: 'Журнал смет' },
    component: () => {
      return import('@/views/documents/estimate.vue');
    },
  },

  {
    path: '/estimate/:id',
    name: 'estimate_view',
    meta: { auth: 1, title: 'Смета' },
    component: () => {
      return import('@/views/documents/views/estimateView.vue');
    },
  },
  {
    path: '/expenses-distribution',
    name: 'expensesDistribution',
    meta: { auth: 1, title: 'Журнал распределения расходов' },
    component: () => {
      return import('@/views/documents/expensesDistribution.vue');
    },
  },

  {
    path: '/expenses-distribution/:id',
    name: 'expensesDistribution_view',
    meta: { auth: 1, title: 'Распределения расходов' },
    component: () => {
      return import('@/views/documents/views/expensesDistributionView.vue');
    },
  },
  {
    path: '/future-payments',
    name: 'futurePayments',
    meta: { auth: 1, title: 'Предстоящие платежи' },
    component: () => {
      return import('@/views/reports/futurePayments.vue');
    },
  },
  {
    path: '/comments',
    name: 'comments',
    meta: { auth: 1, title: 'Переписка' },
    component: () => {
      return import('@/views/comments.vue');
    },
  },
  {
    path: '/records',
    name: 'records',
    meta: { auth: 1, title: 'Разговоры' },
    component: () => {
      return import('@/views/records.vue');
    },
  },
  {
    path: '/accRecords',
    name: 'accRecords',
    meta: { auth: 1, title: 'Проводки' },
    component: () => {
      return import('@/views/acc/accRecord.vue');
    },
  },
  {
    path: '/accCashBox',
    name: 'accCashBox',
    meta: { auth: 1, title: 'Кассы' },
    component: () => {
      return import('@/views/acc/accCashBox.vue');
    },
  },
  {
    path: '/accReportAccount',
    name: 'accReportAccount',
    meta: { auth: 1, title: 'Отчеты по счетам' },
    component: () => {
      return import('@/views/acc/accReportAccount.vue');
    },
  },
  {
    path: '/info',
    name: 'info',
    meta: { auth: 1, title: 'Инфо' },
    component: () => {
      return import('@/views/info.vue');
    },
  },
  {
    path: '/wiki',
    name: 'wiki',
    meta: { auth: 1, title: 'База знаний' },
    component: () => {
      return import('@/views/wiki/wiki.vue');
    },
  },
];

export default {
  routes: [...routes, ...objects, ...cabinet, ...settings, ...analytics],
};
